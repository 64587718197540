import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 480, // xs 브레이크포인트를 500으로 설정
            // ... 기존 코드 ...
        },
    },

    // breakpoint: {
    //     // mobile: 480, // 모바일 기준을 480px로 변경
    //     // tablet: 1024, // 태블릿 기준을 1024px로 변경
    //     // 다른 브레이크포인트도 필요에 따라 설정 가능
    // },

    theme: {
        themes: {
            light: {
                primary: "#0369A1",
                lighter_primary: "#81b4d0",
                alter_black: "#374151",
                alter_grey: "#667080", // 글씨색/적당히 잘보이는 색
                operator: "#AE7525"
            }
        },
    },  
})
